// import { aes, md5 } from '@/functions/encryption'
import { 
	getToken, 
	is_auth_not_expired,
	decrypt_rxdb_password,
} from '@/functions/auth'
import { keys } from '@/functions/localStorage'
import { isMobileCheck } from '@/functions/os'
const {
	// userPassHashKey, 
	// userPassHashFromHashKey, 
	// passwordEncryptedKey,
	encryptKey,
	dbLockKey
} = keys
import {
	removeLastSyncInfo,
	getOldestSyncInfo,
	dateFormatCustom,
	setPendingSyncTime,
	resetPendingSyncTime,
	timeHumanize,
} from '@/functions/sync'
import { createReport } from '@/functions/reportProblem'
import {
	validationCounterIncrement,
	validationCounterReset,
	validationGetAction,
} from '@/functions/validation'
import { deleteItemsFromArray } from '@/functions/arrayProcessing'
import {
	createEventFromCode,
	createEvent,
	mapArrayToEvents,
} from '@/functions/events'
import { isConnectionReady, isOnline } from '@/functions/network'
import { getAsBool, getAsInt } from '@/functions/env'
import { getVersion } from '@/functions/appVersion'

import store from '@/store'
import router from '@/router'
import { $classes } from '@/main'

import staySchema from './database/schemas/Stay.schema'
import stayNoteSchema from './database/schemas/StayNote.schema'
import stayNoteFileSchema from './database/schemas/StayNoteFile.schema'
import stayNoteRoleSchema from './database/schemas/StayNoteRole.schema'
// import patientSexSchema from './database/schemas/PatientSex.schema'
import patientSchema from './database/schemas/Patient.schema'
import patientProfileImageSchema from './database/schemas/PatientProfileImage.schema'
import patientAlertSchema from './database/schemas/PatientAlert.schema'
import alertAttributeSchema from './database/schemas/AlertAttribute.schema'
import attributeAlertCodeSchema from './database/schemas/AlertAttributeCode.schema'
import alertAttributeLevelSchema from './database/schemas/AlertAttributeLevel.schema'
// import patientDataRequestSchema from './database/schemas/PatientDataRequest.schema'
import examinationSchema from './database/schemas/Examination.schema'
import examinationRequestSchema from './database/schemas/ExaminationRequest.schema'
import treatmentSchema from './database/schemas/Treatment.schema'
import drugApplicationSchema from './database/schemas/DrugApplication.schema'
import diagnosisSchema from './database/schemas/Diagnosis.schema'
import procedureSchema from './database/schemas/Procedure.schema'
import orgUnitSchema from './database/schemas/OrgUnit.schema'
import orgUnitPersonSchema from './database/schemas/OrgUnitPerson.schema'

import orgUnitRoomSchema from './database/schemas/OrgUnitRoom.schema'
import orgUnitRoomStaySchema from './database/schemas/OrgUnitRoomStay.schema'

// zwr
import zwrMonitorOrderSchema from './database/schemas/ZwrMonitorOrder.schema'
import zwrPatientAlertSchema from './database/schemas/ZwrPatientAlert.schema'

// tray internal
import drugApplicationInternalSchema from './database/schemas/DrugApplicationInternal.schema'

import {
	syncConfig,
	createRemote,
	kebabCaseToCamelCase,
} from './database/syncConfig'
import { getQueriesForCollection } from './database/indexes'
import { validateSchema } from './database/schemaValidator'
import { isQuotaExceededError, quotaExceededErrorResolution } from '@/functions/error'

const PouchDB = require('pouchdb').default
import pouchdbDebug from 'pouchdb-debug'

import {
	createRxDatabase,
	addRxPlugin,
	removeRxDatabase /* PouchDB */,
} from 'rxdb' // 'rxdb' for v13
import { checkAdapter } from 'rxdb/plugins/pouchdb'
import { addPouchPlugin, getRxStoragePouch } from 'rxdb/plugins/pouchdb'
import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode'
// import { RxDBValidatePlugin } from 'rxdb/plugins/validate'
import { RxDBLeaderElectionPlugin } from 'rxdb/plugins/leader-election'
import { RxDBReplicationCouchDBPlugin } from 'rxdb/plugins/replication-couchdb'
import { RxDBUpdatePlugin } from 'rxdb/plugins/update'
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder'
// import * as PouchdbAdapterHttp from 'pouchdb-adapter-http'
import * as PouchdbAdapterIdb from 'pouchdb-adapter-idb'
// import * as PouchdbAdapterMemory from 'pouchdb-adapter-memory'
import * as CryptoPouch from './database/crypto'

import { removeCollectionStorages } from '@/../node_modules/rxdb/dist/lib/rx-collection-helper.js'

if (process.env.NODE_ENV === 'development') addRxPlugin(RxDBDevModePlugin)

// addRxPlugin(RxDBValidatePlugin)
addRxPlugin(RxDBLeaderElectionPlugin)
addRxPlugin(RxDBReplicationCouchDBPlugin)
addRxPlugin(RxDBUpdatePlugin)
addRxPlugin(RxDBQueryBuilderPlugin)

addPouchPlugin(CryptoPouch)
// addPouchPlugin(PouchdbAdapterHttp)
addPouchPlugin(PouchdbAdapterIdb)
// addPouchPlugin(PouchdbAdapterMemory)

const pounchDBDebugEnabled = getAsBool('VUE_APP_POUNCHDB_DEBUG_ENABLED')

if (pounchDBDebugEnabled) {
	PouchDB.plugin(pouchdbDebug)

	// debug all
	PouchDB.debug.enable('*')

	// only debug queries
	// PouchDB.debug.enable('pouchdb:find');
}

// eslint-disable-next-line no-unused-vars
let doSync = true
if (window.location.hash === '#nosync')
	// eslint-disable-next-line no-unused-vars
	doSync = false

const camelToSnakeCase = (str) =>
		str[0].toLowerCase() +
		str
			.slice(1, str.length)
			.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`),
	modelNameToRxDBCollection = function (modelName) {
		const m = camelToSnakeCase(modelName).replaceAll('_', '-'),
			k = Object.keys(syncConfig),
			i = k.findIndex((key) => {
				return syncConfig[key]?.modelName === m
			})

		if (i > -1) return k[i]
		else
			throw new Error(
				`[modelNameToRxDBCollection] no matching modelName for: "${modelName}"`
			)
	}

const useAdapter = 'idb',
	// eslint-disable-next-line no-unused-vars
	migrationStrategies = {
		1: function () {
			return null // remove the document
		},
	},
	// added '_revisions' to the list to fix replication push bug PT-674
	// all system properties starting with "_" underscore should be ignored, not to break Bulk Get API
	// see node_modules\crypto-pouch\index.js:5, https://docs.couchdb.org/en/3.2.2-docs/api/document/common.html
	cryptoConfigIgnorecommon = [
		'couch_uuid',
		'_attachments',
		'_revisions',
		'_deleted_conflicts',
		'_local_seq',
		'_revs_info',
		'rxdbMeta',
	],
	collections = {
		stays: {
			schema: staySchema,
			cryptoConfig: {
				ignore: [
					...cryptoConfigIgnorecommon,
					'id',
					'org_unit_code',
					'stop_date',
					'remote_id',
				],
			},
		},
		// org_unit_internals: {
		// 	schema: orgUnitInternalSchema,
		// 	cryptoConfig: {
		// 		ignore: [...cryptoConfigIgnorecommon, 'code'],
		// 	},
		// },
		stay_notes: {
			schema: stayNoteSchema,
			cryptoConfig: {
				ignore: [
					...cryptoConfigIgnorecommon,
					'stay_id_ref',
					'person_id_ref',
				],
			},
		},
		stay_note_files: {
			schema: stayNoteFileSchema,
			cryptoConfig: {
				ignore: [...cryptoConfigIgnorecommon, 'stay_note_uuid', 'data', 'mime'],
			},
		},
		stay_note_roles: {
			schema: stayNoteRoleSchema,
			methods: {
				getDictionaryFieldOptions(property) {
					const properties = {
						role: [
							'Admin',
							'Doctor',
							'Nurse',
							'Runner',
							'Assistant',
							'Coordinator',
						],
					}

					return properties?.[property] ?? []
				},
			},
			cryptoConfig: {
				ignore: [...cryptoConfigIgnorecommon, 'stay_note_uuid'],
			},
		},
		patients: {
			schema: patientSchema,
			cryptoConfig: {
				ignore: [
					...cryptoConfigIgnorecommon,
					'remote_id',
					/* 'patient_sex_uuid' */
				],
			},
		},
		// patient_sexs: {
		// 	schema: patientSexSchema,
		// 	cryptoConfig: {
		// 		ignore: [...cryptoConfigIgnorecommon, 'id'],
		// 	},
		// },
		patient_profile_images: {
			schema: patientProfileImageSchema,
			cryptoConfig: {
				ignore: [
					...cryptoConfigIgnorecommon, 
					'patient_uuid', 
					// 'data', 
					// 'mime'
				],
			},
		},
		patient_alerts: {
			schema: patientAlertSchema,
			cryptoConfig: {
				ignore: [
					...cryptoConfigIgnorecommon,
					'examination_uuid',
					'alert_attribute_uuid',
					'patient_uuid',
					'alert_attribute_code_uuid',
					'is_active',
				],
			},
		},
		alert_attributes: {
			schema: alertAttributeSchema,
			cryptoConfig: {
				ignore: [
					...cryptoConfigIgnorecommon,
					'org_unit_uuid',
					'service_uuid',
					'patient_uuid',
				],
			},
		},
		alert_attribute_codes: {
			schema: attributeAlertCodeSchema,
			cryptoConfig: {
				ignore: [
					...cryptoConfigIgnorecommon,
					'alert_attribute_uuid',
					'service_uuid',
				],
			},
		},
		alert_attribute_levels: {
			schema: alertAttributeLevelSchema,
			cryptoConfig: {
				ignore: [...cryptoConfigIgnorecommon, 'alert_attribute_uuid'],
			},
		},
		// patient_data_requests: {
		// 	schema: patientDataRequestSchema,
		// 	cryptoConfig: {
		// 		ignore: [
		// 			...cryptoConfigIgnorecommon,
		// 			'org_unit_code',
		// 			'status',
		// 			'created_date',
		// 		],
		// 	},
		// },
		examinations: {
			schema: examinationSchema,
			cryptoConfig: {
				ignore: [
					...cryptoConfigIgnorecommon,
					'id',
					'result_form_id',
					'result', //TODO
					'order_date',
					'examination_date',
					'status_name',
				],
			},
		},
		examination_requests: {
			schema: examinationRequestSchema,
			cryptoConfig: {
				ignore: [
					...cryptoConfigIgnorecommon,
					'id',
					'order_date',
					'examination_date',
					'status_name',
				],
			},
		},
		treatments: {
			schema: treatmentSchema,
			cryptoConfig: {
				ignore: [
					...cryptoConfigIgnorecommon,
					'id',
					'stay_id_ref',
					'date_created',
					'name',
					'name_code',
				],
			},
		},
		drug_applications: {
			schema: drugApplicationSchema,
			cryptoConfig: {
				ignore: [
					...cryptoConfigIgnorecommon,
					'id',
					'stay_id_ref',
					'order_date',
					'application_date',
				],
			},
		},
		diagnosis: {
			schema: diagnosisSchema,
			cryptoConfig: {
				ignore: [...cryptoConfigIgnorecommon, 'id', 'stay_id_ref'],
			},
		},
		procedures: {
			schema: procedureSchema,
			cryptoConfig: {
				ignore: [...cryptoConfigIgnorecommon, 'id', 'stay_id_ref'],
			},
		},
		org_units: {
			schema: orgUnitSchema,
			cryptoConfig: {
				ignore: [
					...cryptoConfigIgnorecommon,
					'id',
					'code',
					'is_active',
					'is_assigned',
				],
			},
		},
		zwr_monitor_orders: {
			schema: zwrMonitorOrderSchema,
			cryptoConfig: {
				ignore: [
					...cryptoConfigIgnorecommon,
					'id',
					'stay_id_ref',
					'state',
				],
			},
		},
		zwr_patient_alerts: {
			schema: zwrPatientAlertSchema,
			cryptoConfig: {
				ignore: [
					...cryptoConfigIgnorecommon,
					'is_active',
					'patient_uuid',
					'creation_date',
					'examination_date',
				],
			},
		},
		org_unit_persons: {
			schema: orgUnitPersonSchema,
			cryptoConfig: {
				ignore: [
					...cryptoConfigIgnorecommon,
					'person_id_ref',
					'org_unit_uuid',
				],
			},
		},
		org_unit_room_stays: {
			schema: orgUnitRoomStaySchema,
			cryptoConfig: {
				ignore: [
					...cryptoConfigIgnorecommon,
					'stay_id_ref',
					'org_unit_room_couch_uuid',
				],
			},
		},
		org_unit_rooms: {
			schema: orgUnitRoomSchema,
			cryptoConfig: {
				ignore: [...cryptoConfigIgnorecommon, 'org_unit_uuid'],
			},
		},
		drug_application_internals: {
			schema: drugApplicationInternalSchema,
			cryptoConfig: {
				ignore: [
					...cryptoConfigIgnorecommon,
					'person_id_ref',
					'stay_id_ref',
					'drug_application_id_ref',
				],
			},
		},
		
	},
	encryptionEnabled = getAsBool('VUE_APP_DB_ENCRYPTION_ENABLED'),
	VUE_APP_VALIDATION_ENABLED = getAsBool('VUE_APP_VALIDATION_ENABLED'),
	VUE_APP_SYNC_COLLECTION_RETRY_DELAY_MS = getAsInt('VUE_APP_SYNC_COLLECTION_RETRY_DELAY_MS'),
	VUE_APP_SYNC_COLLECTION_RETRY_MAX_ERRORS = getAsInt('VUE_APP_SYNC_COLLECTION_RETRY_MAX_ERRORS'),
	encryptionEnabledInCollections = encryptionEnabled
		? [
				// important to encrypt
				// 'stays',
				'patients',
				'stay_notes',
				'stay_note_files',
				'stay_note_roles',
				// 'org_units', // error PT-994 && PT-996
				// lots of documents
				'treatments',
				'examinations',
				'patient_data_requests',
				// not nesessery to encrypt
				// 'patient_sexs',
				'patient_alerts',
				'alert_attribute_codes',
				'alert_attribute_levels',
				'alert_attributes',
				//zwr
				// 'zwr_monitor_orders',
				'zwr_patient_alerts',
				// tray internal
				'drug_application_internals'
		  ]
		: [],
	_get_rxdb_password = function () {
		return decrypt_rxdb_password()
	},
	_encrypt_collection = async function (db, collectionName) {
		const rxdb_password = _get_rxdb_password()

		console.time(`_encrypt_collection ${collectionName}`)
		const pouch =
			db.collections?.[collectionName].storageInstance.internals.pouch
		await pouch
			.crypto({
				password: rxdb_password,
				ignore: collections[collectionName]?.cryptoConfig?.ignore,
			})
			.then(() =>
				console.timeEnd(`_encrypt_collection ${collectionName}`)
			)
		// restore node_modules\rxdb\src\plugins\pouchdb\custom-events-plugin.ts:newBulkDocs as first handler
		// _wrapperBulkDocs will be called in PouchBulkPostPatch
		pouch._wrapperBulkDocs = pouch.bulkDocs
		pouch.bulkDocs = pouch._originals.bulkDocs // restore newBulkDocs
	},
	_encrypt_collections = async function (db) {
		//crypto
		console.log('DatabaseService: encrypt collections...')
		console.time(`_encrypt_collections`)

		// check
		const wasEnabled = localStorage.getItem(encryptKey)

		if (!wasEnabled){
			try {
				localStorage.setItem(
					encryptKey,
					process.env.VUE_APP_DB_ENCRYPTION_ENABLED
				)
			} catch (e) {
				if (isQuotaExceededError(e)) quotaExceededErrorResolution(e)
				else throw e
			}
		}else if (
			wasEnabled &&
			process.env.VUE_APP_DB_ENCRYPTION_ENABLED !== wasEnabled
		) {
			console.timeEnd(`_encrypt_collections`)

			const e = new Error(
				'Encrypt settings do not match the previous settings'
			)
			e.code = 'EN0'
			
			try {
				localStorage.setItem(
					encryptKey,
					process.env.VUE_APP_DB_ENCRYPTION_ENABLED
				)
			} catch (e) {
				if (isQuotaExceededError(e)) quotaExceededErrorResolution(e)
				else throw e
			}

			throw e
		}

		await Promise.all(
			Object.keys(collections)
				.filter(
					(collectionName) =>
						collections[collectionName]?.cryptoConfig &&
						encryptionEnabledInCollections.includes(collectionName)
				)
				.map(async (collectionName) => {
					return await _encrypt_collection(db, collectionName)
				})
		)
		
		console.timeEnd(`_encrypt_collections`)
		console.log('DatabaseService: encrypted collections.')
	},
	_add_collections = async function (db) {
		// create collections
		console.log('DatabaseService: create collections...')

		Object.keys(collections).forEach((collectionName) =>
			validateSchema(collectionName, collections[collectionName].schema)
		)

		await db.addCollections(collections)
		console.log('DatabaseService: created collections.')

		await _encrypt_collections(db)
	},
	// eslint-disable-next-line no-unused-vars
	_add_one_collection = async function (db, collectionName) {
		console.log(`DatabaseService: create collection "${collectionName}"`)

		if (!db[collectionName]) {
			validateSchema(collectionName, collections[collectionName].schema)

			await db.addCollections({
				[collectionName]: collections[collectionName],
			})

			if (
				collections[collectionName]?.cryptoConfig &&
				encryptionEnabledInCollections.includes(collectionName)
			)
				await _encrypt_collection(db, collectionName)
		} else
			console.warn(
				`[_add_one_collection] collection "${collectionName}" exists`
			)
	},
	_wait_for_leadership = function (db) {
		// show leadership in title
		db?.waitForLeadership().then(() => {
			console.log('isLeader now')
		})
	},
	_create_db = async function () {
		console.log('DatabaseService: creating database..')
		let db = await createRxDatabase({
			name: 'hosp',
			storage: await _get_adapter(),
			multiInstance: false,
		})

		console.log('DatabaseService: created database')
		window.db = db // write to window for debugging

		_wait_for_leadership()

		return db
	},
	_remove_db = async function (db) {
		console.debug(`[_remove_db] starting...`)
		await store.dispatch('CurrentDatabase/updateInit', {
			init: false,
		})
		await store.dispatch('CurrentDatabase/resetReplicationInfo')
		await db?.remove()
		removeLastSyncInfo()
		console.debug(`[_remove_db] finished`)
	},
	_create_resolve_error_retry = async function (e, db, retryNumber=0) {
		const _limit = 5
		
		if(retryNumber < _limit){
			try {
				db = await _create_resolve_error(e, db)
			} catch (e1) {
				retryNumber += 1
				db = await _create_resolve_error_retry(e1, db, retryNumber)
				return db
			}
		} else throw e
		
		return db
	},
	_create_resolve_error = async function (e, db) {
		let resolveByResetDb = false

		if (e.code === 'DB6') {
			const { name } = e.parameters
			if(name)
				console.debug(`Wykryto róznice w schemacie kolekcji ${name}.`)
			else console.debug('Inna instancja db utworzyła tę kolekcję z innym schematem.')

			await createEventFromCode('db-error-db6')
			resolveByResetDb = true
		} else if (e.code === 'EN0') {
			console.debug(`Wykryto zmianę ustawień szyfrowania`)
			
			await createEventFromCode('db-error-en0')
			resolveByResetDb = true
		} else if (e.code === 'DB8') { // TODO test resolution
			console.debug(`Wykryto duplikat db`)
			

			await createEventFromCode('db-error-db8')
			resolveByResetDb = true
		}

		if (resolveByResetDb) {
			console.warn(e)
			console.debug(`Rozwiązanie błędu przez usunięcie bazy danych...`)

			await _remove_db(db)
			await remove_rx_database()
			db = null
			
			console.debug(`Próba ponownego utworzenia bazy danych...`)
			db = await _create_db()
			await _add_collections(db)
			return db
		} else throw e
	},
	/**
	 * creates the database
	 */
	_create = async function () {
		console.time('_create')
		
		if(!get_rxdb_password())
			return

		let db = null

		// implement delete on change schema
		// const collectionsWithMigrationStrategy = {}

		// Object.keys(collections).forEach((collectionName) => {
		// 	collectionsWithMigrationStrategy[collectionName] = {
		// 		...collections[collectionName],
		// 		migrationStrategies,
		// 	}
		// })

		try {
			db = await _create_db()
			await _add_collections(db)
		} catch (e) {
			db = await _create_resolve_error_retry(e, db)
		}

		console.log('DatabaseService: adding hooks...')
		const filterNotDeleted = (a) => !a?.deleted,
			mapUuids = (a) => a.couch_uuid,
			toDelete = (arr) => arr.filter(filterNotDeleted).map(mapUuids)

		db.collections.patient_profile_images.preInsert((docObj) => {
			const patient_uuid = docObj.patient_uuid

			return db.collections.patient_profile_images
				.find({
					selector: {
						patient_uuid,
					},
				})
				.exec()
				.then((exists) => {
					if (exists?.length > 0)
						return db.collections.patient_profile_images.bulkRemove(
							toDelete(exists)
						)
					else return false
				})
		}, true)

		db.collections.stay_notes.postRemove(function (docObj) {
			const stay_note_uuid = docObj.couch_uuid,
				stay_note_files = db.collections.stay_note_files
					.find({
						selector: {
							stay_note_uuid,
						},
					})
					.exec()
					.then((exists) => {
						if (exists?.length > 0) {
							console.debug(`[remove stay_note_files]`, exists)
							return db.collections.stay_note_files.bulkRemove(
								toDelete(exists)
							)
						} else return false
					}),
				stay_note_roles = db.collections.stay_note_roles
					.find({
						selector: {
							stay_note_uuid,
						},
					})
					.exec()
					.then((exists) => {
						if (exists?.length > 0) {
							console.debug(`[remove stay_note_roles]`, exists)
							return db.collections.stay_note_roles.bulkRemove(
								toDelete(exists)
							)
						} else return false
					})

			return Promise.all([stay_note_files, stay_note_roles])
		}, false)

		db.collections.org_unit_room_stays.preInsert((docObj) => {
			const stay_id_ref = docObj.stay_id_ref

			return db.collections.org_unit_room_stays
				.find({
					selector: {
						stay_id_ref,
					},
				})
				.exec()
				.then((exists) => {
					if (exists?.length > 0)
						return db.collections.org_unit_room_stays.bulkRemove(
							toDelete(exists)
						)
					else return false
				})
		}, true)
		
		db.collections.zwr_monitor_orders.preInsert((docObj) => {
			const stay_id_ref = docObj.stay_id_ref

			return db.collections.zwr_monitor_orders
				.find({
					selector: {
						stay_id_ref,
					},
				})
				.exec()
				.then((exists) => {
					if (exists?.length > 0)
						console.debug(`Wystąpił duplikat zlecenia monitorowania. Id pobytu: "${stay_id_ref || '-'}", Coach_uuid nowego obiektu: "${docObj?.coach_uuid || '-'}" `)
						// return db.collections.zwr_monitor_orders.bulkRemove(
						// 	toDelete(exists)
						// )
					return false
				})
		}, true)
		
		db.collections.drug_application_internals.preInsert((docObj) => {
			const drug_application_id_ref = docObj.drug_application_id_ref

			return db.collections.drug_application_internals
				.find({
					selector: {
						drug_application_id_ref,
					},
				})
				.exec()
				.then((exists) => {
					if (exists?.length > 0)
						return db.collections.drug_application_internals.bulkRemove(
							toDelete(exists)
						)
					return false
				})
		}, true)

		console.log('DatabaseService: added hooks')

		console.log('DatabaseService: DB creation completed.')

		console.timeEnd('_create')

		return db
	}

export const tablesListFilter = function (collections) {
		const role = store.state.CurrentUser?.instance?.role?.toLowerCase()

		if (role)
			collections = collections.filter((c) =>
				syncConfig[c]?.allowedRules?.includes(role)
			)

		return collections
	},
	getTablesList = function () {
		return tablesListFilter(Object.keys(syncConfig))
	},
	getTablesToSync = function () {
		const tables = deleteItemsFromArray(
			['stay_note_files', 'stay_note_roles'],
			getTablesList()
		)

		return tables
	},
	getTablesListPrepareToSync = function (collections = []) {
		collections = deleteItemsFromArray(
			['stay_note_files', 'stay_note_roles'],
			collections
		)

		return collections
	},
	pouchSettings = {
		auto_compaction: false,
		// revs_limit: 2,
	},
	_get_adapter = async function () {
		const ok = await checkAdapter(useAdapter)
		if (ok)
			console.log(
				`DatabaseService: database adapter '${useAdapter}' supported.`
			)
		else {
			console.error(
				`DatabaseService: database '${useAdapter}' adapter not supported.`
			)

			return
		}

		// pouchSettings as second parametr https://rxdb.info/adapters.html
		return getRxStoragePouch(useAdapter, pouchSettings)
	},
	remove_rx_database = async function () {
		// remove a database without its instance
		console.debug(`Usuwanie bazy danych...`)
		removeRxDatabase('hosp', await _get_adapter())
		removeLastSyncInfo()
		console.debug(`Usnięto bazę danych.`)
	},
	get_rxdb_password = function () {
		const rxdb_password = _get_rxdb_password()
		
		// check if password to rxdb exists
		if (!rxdb_password) {
			console.error(`DatabaseService: database password don't exists. Please login by online method.`)
			
			return null
		}
		
		return rxdb_password
	}

class DatabaseService {
	static #singleton = null
	static #initailizedPromise = null
	static async singleton() {
		if (this.#initailizedPromise == null) {
			this.#singleton = new DatabaseService()

			this.#singleton.removeDatabaseUnlock()

			this.#initailizedPromise = this.#singleton.initialize()
		}
		return await this.#initailizedPromise
	}

	constructor() {}

	async initialize() {
		this.db = await _create()

		this.removeDatabaseUnlock()

		return this
	}

	async checkIfDbExistIfNotCreateOne() {
		console.debug(`[checkIfDbExistIfNotCreateOne] checking if db exists...`)

		try {
			if (!this.removeDatabaseCheck()) this.checkDB()
		} catch (error) {
			console.debug(
				`[checkIfDbExistIfNotCreateOne] creating new db instance...`
			)
			this.db = await _create()
		}
	}

	async checkNetwork() {
		await isOnline()

		await store.dispatch('CurrentUser/updateNetworkStatus')
	}

	checkDB() {
		if (!this.db || this.db?.destroyed === true)
			throw new Error("db instance don't exist")
	}

	async syncPartial() {
		await this.syncDatabaseV2({
			code: 'default-partial',
			collections: [
				'org_units',
				'org_unit_persons',
				'patient_profile_images',
				'stay_notes',
				'stay_note_files',
				'stay_note_roles',
			],
			detectDiffCollections: ['org_units'],
		})
	}

	async syncPartialForZwr() {
		await this.syncDatabaseV2({
			code: 'zwr-partial',
			collections: ['zwr_monitor_orders', 'zwr_patient_alerts'],
			detectDiffCollections: ['zwr_monitor_orders', 'zwr_patient_alerts'],
			silent: true,
		})
	}

	async syncZwrAfterMonitorChange() {
		await this.syncDatabaseV2({
			code: 'zwr-partial-1',
			collections: ['zwr_monitor_orders'],
			detectDiffCollections: [],
			silent: true,
		})
	}

	async syncAfterRoomUpdate() {
		await this.syncDatabaseV2({
			code: 'partial-stay-room',
			collections: ['org_unit_rooms', 'org_unit_room_stays'],
			detectDiffCollections: [],
			silent: true,
			skipValidation: true,
		})
	}

	async syncDatabase() {
		await this.syncDatabaseV2({
			forceIndexes: true,
		})
	}

	async syncAfterValidation(collections = []) {
		if (collections?.length > 0)
			await this.syncDatabaseV2({
				code: 'after-validation',
				collections: collections,
				detectDiffCollections: [],
				silent: true,
				skipValidation: false,
			})
	}
	
	async syncAfterAddNote() {
		await this.syncDatabaseV2({
			code: 'after-note',
			collections: ['stay_notes', 'stay_note_files', 'stay_note_roles'],
			detectDiffCollections: [],
			silent: true,
			skipValidation: true,
		})
	}
	
	async syncAfterAddProfileImage() {
		await this.syncDatabaseV2({
			code: 'after-patient-profile',
			collections: ['patient_profile_images'],
			detectDiffCollections: [],
			silent: true,
			skipValidation: true,
		})
	}
	
	async syncTrayChange() {
		await this.syncDatabaseV2({
			code: 'tray-change',
			collections: ['drug_application_internals'],
			detectDiffCollections: [],
			silent: true,
			skipValidation: true,
		})
	}
	
	async syncAfterSign() {
		await this.syncDatabaseV2({
			code: 'after-sign',
			collections: ['treatments'],
			detectDiffCollections: [],
			silent: true,
			skipValidation: true,
		})
	}
	
	async syncAfterCreateExaminationRequest() {
		await this.syncDatabaseV2({
			code: 'after-examination-request',
			collections: ['examination_requests'],
			detectDiffCollections: [],
			silent: true,
			skipValidation: true,
		})
	}

	async validateDatabase({ collections = [...getTablesList()], isTest = false } = {}) {
		let data = {}

		console.debug(`validateDatabase`, collections)
		console.time(`validateDatabase`)

		if (collections?.length === 0)
			throw new Error(`validateDatabase passed 0 collections`)

		await Promise.all(
			collections.map(async (collectionName) => {
				console.time(`validateDatabase ${collectionName}`)

				const docs_amount = await this.db[collectionName].count().exec()
				const couch_uuids = (
					await this.db[collectionName].find().exec()
				).map((doc) => doc.couch_uuid)

				console.timeEnd(`validateDatabase ${collectionName}`)

				return {
					docs_amount,
					couch_uuids,
					collectionName,
				}
			})
		).then((collectionsWithData) => {
			collectionsWithData.forEach(async (obj) => {
				const modelName = kebabCaseToCamelCase(
					syncConfig[obj.collectionName].modelName
				)

				delete obj.collectionName

				data[modelName] = obj
			})
		})

		if (Object.keys(collections).length !== collections.length)
			throw new Error(
				`validateDatabase collections length dont match object's keys length`
			)

		let validate_rxdb = null

		try {
			// await isOnline()
			await store.dispatch('CurrentUser/requireAuthenticatedClasses')

			validate_rxdb = await $classes.Person?.validate_rxdb({
				app_data: data,
			})

			const {
				errors,
				warnings,
				status,
				is_patient_data_request_in_progress,
			} = validate_rxdb

			if (!getAsBool('VUE_APP_VALIDATION_DISABLE_WARNING'))
				// PT-1410
				await mapArrayToEvents(warnings, 'Warning')

			await mapArrayToEvents(errors, 'Fail', { skipNotification: true })

			if (status === 'error')
				throw new Error('Walidacja danych aplikacji')
			else if (!getAsBool('VUE_APP_VALIDATION_DISABLE_WARNING_REPORT') && status === 'warning')
				await createReport({
					title: `Walidacja danych aplikacji powodzeniem, ale z ostrzezeniem.`,
					message: `Obiekt walidacji ${JSON.stringify(
						validate_rxdb
					)}`,
					submission_method: 'automatic',
				})

			if (is_patient_data_request_in_progress) {
				try {
					validationCounterIncrement()
					setPendingSyncTime()

					const timeHumanized = timeHumanize({
						value: parseInt(
							process.env
								.VUE_APP_SYNC_RETRY_PEROID_IN_MILLISECONDS
						),
					})

					// await createEvent({
					// 	status: 'Info',
					// 	details: `Zaplanowano nastepną synchronizacje za ${timeHumanized}.`,
					// })
					await createEventFromCode('db-sync-planned', {
						injections: [
							{
								code: 'time_humanized',
								ret: timeHumanized,
							},
						],
					})

					const self = this,
						{ error_collections_in_progress } = validate_rxdb

					setTimeout(async function () {
						await self.syncAfterValidation(
							error_collections_in_progress.map(i => modelNameToRxDBCollection(i))
						)
					}, parseInt(
						process.env.VUE_APP_SYNC_RETRY_PEROID_IN_MILLISECONDS
					) + 1)
				} catch (error) {
					console.debug(error)
					validationCounterReset()
				}
			}
			
			await createEventFromCode('db-validate-success')

			validationCounterReset()

			console.timeEnd(`validateDatabase`)
		} catch (e) {
			console.error(e)

			await createReport({
				title: `Walidacja danych aplikacji zakończona niepowodzeniem.`,
				message: `Obiekt walidacji ${JSON.stringify(validate_rxdb)}`,
				submission_method: 'automatic',
			})

			console.timeEnd(`validateDatabase`)

			try {
				const isInitialized =
						store.state.CurrentDatabase?.initialization
							?.isInitialized,
					{ error_collections } = validate_rxdb

				/* 
					gdy rozpoczęta została nowa synchronizacja od razu po zakończeniu starej, 
				    np zostały wykryte zmiany w trakcie częściowego synchro.
				*/
				if(isTest){
					console.debug(`[validateDatabase] testowy tryb włączony, synchronizacja nie zostanie zainicjowana`)
					
					return validate_rxdb
				} else if (!isInitialized) {
					validationCounterIncrement()
					
					const a = validationGetAction()
					
					if(a === 'sync-partial')
						await this.syncAfterValidation(error_collections.map(i => modelNameToRxDBCollection(i)))
					else if(a === 'reset-full-sync'){
						await this.removeDatabase({ resetValidationCounter: false })
						await this.syncDatabaseV2()
					}
				}
			} catch (counterError) {
				console.debug(counterError)
			}
		}
	}

	async syncDatabaseV2({
		code = 'default-full',
		collections = getTablesList(),
		detectDiffCollections = [],
		silent = false,
		forceIndexes = false,
		skipValidation = false,
	} = {}) {
		const eventStartCode = `db-sync-started${code ? `-${code}` : null}`,
			eventFailCode = `db-sync-start-fail`,
			allowedCodes = [
				'default-full',
				'zwr-partial',
				'default-partial',
				'zwr-partial-1',
				'zwr-partial-2',
				'partial-stay-room',
				'after-validation',
				'after-note',
				'after-patient-profile',
				'tray-change',
				'after-sign',
				'after-examination-request'
			], // before add new code, you should add event for start
			infoLowAppVersion =
				'Wykryto nową wersję aplikacji, proszę o zaktualizowanie'

		try {
			if (!allowedCodes.includes(code))
				throw new Error(`Illegal sync code: ${code}`)

			this.checkDB()

			if (is_auth_not_expired()) {
				await store.dispatch('CurrentUser/updateNetworkStatus')

				await isConnectionReady()
				
				const skipVersionCheck = getAsBool('VUE_APP_SKIP_VERSION_CHECK')

				if(!skipVersionCheck) {
					const updatePending = await getVersion()
					if (updatePending) 
						throw new Error(infoLowAppVersion)
				}

				await store.dispatch('CurrentDatabase/resetReplicationInfo')
				const queriesPending = {}

				const { oldestDontExist } = getOldestSyncInfo()

				if (oldestDontExist || forceIndexes)
					collections.forEach((collectionName) => {
						const queries = getQueriesForCollection(collectionName)

						if (queries?.length > 0)
							queriesPending[collectionName] = queries
					})

				const config = {
					code,
					collections: [...collections],
					detectDiffCollections,
					silent,
					forceIndexes,
					skipValidation,
				}

				console.debug(`syncDatabaseV2`, config, {
					oldestDontExist,
					queriesPending,
				})

				await store.dispatch('CurrentDatabase/updateInit', {
					init: true,
					config: {
						code: config.code,
						silent: config.silent,
						skipValidation: config.skipValidation,
					},
					...config,
					indexes: { queriesPending },
				})

				const isInitialized =
					store.state.CurrentDatabase?.initialization?.isInitialized

				let initalizedCollections =
					store.state.CurrentDatabase?.initialization?.collections ||
					[]

				if (!isInitialized && initalizedCollections?.length > 0)
					initalizedCollections = []

				const collectionsToSync = getTablesListPrepareToSync([
					...new Set([...collections, ...initalizedCollections]),
				])

				resetPendingSyncTime()

				await this.syncTables(collectionsToSync, detectDiffCollections)

				if (!silent) await createEventFromCode(eventStartCode)
			} else throw new Error(`RTE`)
		} catch (e) {
			if (e.message === 'RTE') router.push({ name: 'login' })

			if (silent) await createEventFromCode(eventStartCode)

			if (e.message === infoLowAppVersion)
				await createEventFromCode('db-sync-start-fail-low-app-version')
			else await createEventFromCode(eventFailCode)

			await store.dispatch('CurrentDatabase/replicationUpdateStatus')

			throw e
		}
	}

	async syncTables(collections = [], detectDiffCollections = []) {
		return Promise.all(
			collections.map(async (collectionName) =>
				detectDiffCollections.includes(collectionName)
					? await this.syncTableCheckDiffAndSyncDatabase(
							collectionName
					  )
					: await this.syncTable(collectionName)
			)
		)
	}

	checkCollection(collection) {
		this.checkDB()

		if (!collection) throw new Error(`No collection name.`)

		if (!this.db?.[collection])
			throw new Error("Collection instance don't exist.")
	}

	async syncTablePre(collection) {
		console.log(`DatabaseService: sync [${collection}]`)

		try {
			await this.checkNetwork()

			this.checkCollection(collection)
		} catch (e) {
			await store.dispatch('CurrentDatabase/updateReplicationError', {
				table: collection,
				error: e,
			})

			throw e
		}
	}

	replicationStateAddSubs(replicationState, config) {
		replicationState.change$.subscribe((change) =>
			this.replicationStateChange(change, config)
		)
		replicationState.error$.subscribe((error) =>
			this.replicationStateError(error, config)
		)
		replicationState.denied$.subscribe((docData) =>
			console.log(`replicationState - denied$ - ${config}`, docData)
		)
		replicationState.active$.subscribe((active) =>
			this.replicationStateActive(active, config)
		)
		// replicationState.alive$.subscribe((alive) =>
		// 	console.log(`replicationState - alive$ - ${config}`, alive)
		// )
	}

	async syncTableCheckDiffAndSyncDatabase(collection = 'org_units') {
		await this.syncTablePre(collection)

		const token = await getToken()

		if (!token) {
			throw new Error(
				`Access token and refresh token expired, please login by online method.`
			)
		}

		await store.dispatch('CurrentDatabase/updateReplicationInit', {
			table: collection,
		})

		const config = collection,
			remote = new PouchDB(createRemote(syncConfig[config]?.modelName), {
				fetch: function (url, opts) {
					opts.headers.set('Authorization', `Bearer ${token}`)
					return PouchDB.fetch(url, opts)
				},
			}),
			replicationState = this.db[config].syncCouchDB({
				...syncConfig[config],
				remote: remote,
			}),
			self = this

		this.replicationStateAddSubs(replicationState, config)

		replicationState.complete$.subscribe(async (completed) => {
			let docs_read_sum = 0,
				last_change_datetime

			const { pull, push, docs_read, last_seq } = completed

			if (last_seq) last_change_datetime = last_seq

			if (pull) {
				docs_read_sum += pull?.docs_read
				last_change_datetime = pull?.last_seq
			}
			if (pull) docs_read_sum += push?.docs_read

			if (docs_read) docs_read_sum += docs_read

			if (docs_read_sum > 0) {
				const lastChangeDatetime = dateFormatCustom(last_change_datetime)
				
				if(lastChangeDatetime !== 'Invalid date')
					await createEventFromCode('db-sync-change-remote-detected', {
						injections: [
							{
								code: 'last_change_datetime',
								ret: dateFormatCustom(last_change_datetime),
							},
						],
					})
				else
					await createEventFromCode('db-sync-change-remote-detected-without-date')

				await self.syncDatabaseV2({
					code: 'default-full',
					collections: getTablesList().filter(
						(collection) => collection !== config
					),
					forceIndexes: true,
				})
			}

			this.replicationStateComplete(completed, config, self)
		})
	}

	replicationStates = []

	async syncTable(table, customSyncConfig = {}) {
		await this.syncTablePre(table)

		const token = await getToken()

		if (!token) {
			throw new Error(
				`Access token and refresh token expired, please login by online method.`
			)
		}

		await store.dispatch('CurrentDatabase/updateReplicationInit', {
			table: table,
		})

		const config = table,
			remote = new PouchDB(createRemote(syncConfig[config]?.modelName), {
				fetch: function (url, opts) {
					opts.headers.set('Authorization', `Bearer ${token}`)
					return PouchDB.fetch(url, opts)
				},
			}),
			replicationState = this.db[config].syncCouchDB({
				...syncConfig[config],
				...customSyncConfig,
				remote: remote,
			}),
			self = this
		
		if(this.db[config]?.internalStorageInstance?.closed === true)
			throw new Error(`Internal storage instance for collection "${config}" is closed.`)

		this.replicationStateAddSubs(replicationState, config)

		replicationState.complete$.subscribe((completed) =>
			this.replicationStateComplete(completed, config, self)
		)

		this.replicationStates.push(replicationState)
		
		// lock screen
		const isMobile = isMobileCheck()
		if(isMobile) {
			const visibilityChangeHandler = async () => {
				const isActive = store.state.CurrentDatabase?.replications?.[config]?.active
				
				if(isActive){
					console.debug(`adding event listener [visibilitychange]`)
					if (document.hidden) {
						if (!replicationState.canceled) {
							console.debug(`[isMobileCheck]`, isMobile)
							console.warn(`Document is hidden, stopping replication for table ${config}.`)
							await replicationState.cancel()
						}
					} else {
						console.warn(`Document is visible, restarting replication for table ${config}.`)
						await this.syncTable(table, customSyncConfig)
					
						console.debug(`removing event listener [visibilitychange]`)
						document.removeEventListener('visibilitychange', visibilityChangeHandler)
					}
				}
			}
			document.addEventListener('visibilitychange', visibilityChangeHandler)
		}

		return replicationState
	}

	async collectionRemoveCustom(collectionName) {
		this.db[collectionName].storageInstance.close()
		this.db[collectionName]._subs.forEach(function (sub) {
			return sub.unsubscribe()
		})
		const { storage, internalStore, token, name, hashFunction } = this.db
		await removeCollectionStorages(
			storage,
			internalStore,
			token,
			name,
			collectionName,
			hashFunction
		)
		delete this.db.collections[collectionName]

		// await this.db[table]?.remove()
	}

	async removeTable(table) {
		this.checkCollection(table)

		// 👇 this is alternative for await this.db[table]?.remove()
		await this.collectionRemoveCustom(table)

		await _add_one_collection(this.db, table)
	}

	async removeTables(tables) {
		return Promise.all(
			tables.map(async (table) => await this.removeTable(table))
		)
	}

	removeDatabaseLock() {
		console.debug(`[removeDatabaseLock] locking...`)
		try {
			sessionStorage.setItem(dbLockKey, true)
		} catch (e) {
			if (isQuotaExceededError(e)) quotaExceededErrorResolution(e)
			else throw e
		}
	}

	removeDatabaseUnlock() {
		console.debug(`[removeDatabaseLock] unlocking...`)
		sessionStorage.removeItem(dbLockKey)
	}

	removeDatabaseCheck() {
		const isLocked = sessionStorage.getItem(dbLockKey)
		console.debug(
			`[removeDatabaseCheck] isLocked=${isLocked ? 'true' : 'false'}`
		)
		if (isLocked) return true
		else return false
	}

	isReplicationsRunning() {
		const replicationStatesNotCanceled = this.replicationStates.filter(
			(replicationState) => !replicationState.canceled
		)

		const isInitialized =
			store.getters['CurrentDatabase/isReplicationInitialized']

		return isInitialized || replicationStatesNotCanceled?.length > 0
	}

	async replicationsCancel() {
		const isReplicationsRunning = this.isReplicationsRunning()
		
		function withTimeouts(promise, resolveMs, rejectMs) {
			return Promise.race([
				promise,
				new Promise((resolve) =>
					setTimeout(() => resolve('Timeout resolved'), resolveMs)
				),
				new Promise((_, reject) =>
					setTimeout(() => reject(new Error('Promise timed out')), rejectMs)
				),
			])
		}

		if (isReplicationsRunning) {
			console.debug(`[replicationsCancel] starting...`)
			return Promise.all(
				this.replicationStates
					.filter((replicationState) => replicationState && !replicationState.canceled)
					.map(
						async (replicationState, replicationStateIndex, arr) => {
							// await replicationState.cancel()
							try {
								const result = await withTimeouts(replicationState.cancel(), 5000, 30000);
			                    if (result === 'Timeout resolved') {
			                        console.info(`[replicationsCancel] Timeout resolved for replicationState ${replicationStateIndex + 1}/${arr?.length}`);
			                    } else {
			                        console.debug(`[replicationsCancel] canceled ${replicationStateIndex + 1}/${arr?.length}`);
			                    }
							} catch (e) {
								console.warn(`[replicationsCancel] error canceling replicationState: ${e.message}`);
								throw e
							}
						}
					)
			).then(async () => {
				this.replicationStates = []
				await createEventFromCode('db-sync-aborted')
				console.debug(`[replicationsCancel] finished.`)
			})
		}
	}
	
	async stopSync() {
		console.debug(`[stopSync] started...`)
		
		await store.dispatch('CurrentDatabase/updateInit', {
			init: false,
		})
		
		await store.dispatch('CurrentDatabase/resetReplicationInfo')
		await this.replicationsCancel()
		
		console.debug(`[stopSync] finished.`)
	}

	async removeDatabase({ resetValidationCounter = true } = {}) {
		if (!this.removeDatabaseCheck()) {
			console.debug(`[removeDatabase] started...`)
			this.removeDatabaseLock()
			await this.replicationsCancel()
			await _remove_db(this.db)
			removeLastSyncInfo()
			if (resetValidationCounter) validationCounterReset()
			this.removeDatabaseUnlock()
			await this.checkIfDbExistIfNotCreateOne()
			await createEventFromCode('db-cache-remove')
			console.debug(`[removeDatabase] finished.`)
		} else console.error(`[removeDatabase] db remove locked.`)
	}

	getTablesList() {
		return getTablesList()
	}

	getTablesToSync() {
		return getTablesToSync()
	}

	async replicationStateChange(change, config) {
		console.log(`replicationState - change$ - ${config}`, change)

		if (change.docs) change.docs = null
		else if (change?.pull?.docs || change?.push?.docs) {
			if (change?.pull?.docs) change.pull.docs = null
			else change.push.docs = null
		}

		await store.dispatch('CurrentDatabase/updateReplicationChangeInfo', {
			table: config,
			change: change,
		})

		await this.checkNetwork()
	}
	async replicationStateActive(active, config) {
		console.log(`replicationState - active$ - ${config}`, active)

		await store.dispatch('CurrentDatabase/updateReplicationActivityInfo', {
			table: config,
			active: active,
		})

		if (active) await this.checkNetwork()
	}
	async replicationStateComplete(completed, config, self) {
		console.log(`replicationState - complete$ - ${config}`, completed)
		
		if(completed?.status === `cancelled`){
			console.warn(`[replicationStateComplete] Synchronizacja kolekcji "${config}" została przerwana.`)
			return
		}

		if (completed.docs) completed.docs = null
		else if (completed?.pull?.docs || completed?.push?.docs) {
			if (completed?.pull?.docs) completed.pull.docs = null
			else completed.push.docs = null
		}

		if ((completed?.push?.ok && completed?.pull?.ok) || completed?.ok) {
			if (config == 'stay_notes')
				await self.syncTables(['stay_note_files', 'stay_note_roles'])

			const code =
					store.state?.CurrentDatabase?.initialization?.config?.code,
				codeZwr1 = 'zwr-partial-1'

			if (
				code === codeZwr1 ||
				(code?.includes(codeZwr1) && config == 'zwr_monitor_orders')
			)
				await this.syncDatabaseV2({
					code: 'zwr-partial-2',
					collections: ['zwr_patient_alerts'],
					silent: true,
				})

			await store.dispatch(
				'CurrentDatabase/updateReplicationCompleteInfo',
				{
					table: config,
					completed: completed,
				}
			)

			// last time fully sync
			const queries =
				store.state?.CurrentDatabase?.initialization?.indexes
					?.queriesPending?.[config] || []

			if (queries?.length > 0)
				await this.createIndexV2(config, queries).then(
					async () => await this.onSyncCompleted()
				)
			else await this.onSyncCompleted()
		}
	}
	async replicationStateError(error, config) {
		console.log(`replicationState - error$ - ${config}`, error)

		let messageCustom = null

		if (error?.code == 401)
			messageCustom = `Proszę o zalogowanie się w aplikacji pod ${window.location.origin}/login.`
		else if (
			error?.code == 400 &&
			['stay_note_files', 'stay_note_rules'].includes(config)
		) {
			// eslint-disable-next-line max-len
			messageCustom = `Proszę o synchronizacje kolekcji ${config} po synchronizacji kolekcji stay_note, w przeciwnym razie dodawane są obiekty, z (obowiązakową) referencją do nieistniejącego obiektu. To powoduje błąd. Powiązane zadanie: PT-596.`
		}

		let errorMessage = `Couch sync error, collection ${config}, message: ${
			error?.message ?? 'unknown'
		}.${messageCustom ? '\n\n' + messageCustom : ''}`

		console.error(errorMessage)

		await store.dispatch('CurrentDatabase/updateReplicationError', {
			table: config,
			error: error,
		})

		// await this.checkNetwork()

		const errorCount =
				store.state.CurrentDatabase?.replications?.[config]?.errorCount,
			active = store.state.CurrentDatabase?.replications?.[config]?.active

		try {
			if (errorCount <= VUE_APP_SYNC_COLLECTION_RETRY_MAX_ERRORS || (typeof errorCount == undefined && active)) {
				const errorCountVal =
					typeof errorCount == undefined ? 2 : errorCount + 1

				console.debug(
					`[replicationStateError] retring sync collection "${config}"`
				)

				// retrying
				await this.checkNetwork()
				
				if(error.message === 'Load failed')
					throw new Error('No internet access. (Load failed)')
				else if (error.message === 'Failed to fetch')
					throw new Error('No internet access. (Failed to fetch)')
				else console.debug(error.message)

				await createEvent({
					status: 'Warning',
					details: `Synchronizacja kolekcji ${config}, próba: ${errorCountVal}, wystąpił błąd: '${error.message}'.`,
				})

				await this.removeTable(config)
				await setTimeout(async () => await this.syncTable(config), VUE_APP_SYNC_COLLECTION_RETRY_DELAY_MS)
			} else throw error
		} catch (error) {
			const errorMessagesNoInternet = [
				'No internet access.',
				'No internet access. (Load failed)',
				'No internet access. (Failed to fetch)'
			]
			  
			if(errorMessagesNoInternet.includes(error?.message)) {
				await createEventFromCode('db-sync-fail-by-offline') //TODO
				await this.stopSync()
			} else {
				await createEventFromCode('db-sync-fail-for-collection', {
					injections: [
						{
							code: 'collection_name',
							ret: config
						},
						{
							code: 'error_message',
							ret: error?.message || '-'
						}
					]
				}) 
			}
		}
	}

	async testSyncPerformances(secenarios) {
		return Promise.all(
			secenarios.map(
				async (secenario) => await this.testSyncPerformance(secenario)
			)
		)
	}

	async testSyncPerformance(
		secenario = { collectionName: null, batchSizes: [] }
	) {
		if (!secenario.batchSizes || secenario.batchSizes?.length < 1)
			secenario.batchSizes = [
				5, 10, 20, 100, 200, 300, 400, 500, 1000, 1500, 3000, 4500,
				5000, 6500, 7000,
			] /* .reverse() */

		await this.removeTable(secenario.collectionName)

		const replicationState = await this.syncTable(
			secenario.collectionName,
			{ options: { batch_size: secenario.batchSizes[0] } }
		)

		replicationState.complete$.subscribe(async (completed) => {
			if (completed) {
				console.debug(`[testSyncPerformance]`, secenario, completed)

				await store.dispatch('CurrentDatabase/updateReplicationTest', {
					collectionName: secenario.collectionName,
					batchSize: secenario.batchSizes[0],
					result: completed,
				})

				secenario.batchSizes.shift()

				if (secenario.batchSizes?.length > 0)
					await this.testSyncPerformance({ ...secenario })
			}
		})
	}

	async createIndexV2(collectionName = null, queries = []) {
		if (queries?.length > 0 && collectionName) {
			const t0 = `[index creator] ${collectionName}`
			console.time(t0)

			return await Promise.all(
				queries
					.filter(
						(c) =>
							!store.state?.CurrentDatabase?.initialization?.indexes?.queriesDone?.[
								collectionName
							]?.includes(c)
					)
					.map(async (c, i) => {
						const t1 = `[index creator] ${collectionName} zapytanie ${
							i + 1
						}`
						console.time(t1)

						await this.db?.[collectionName].find(c).exec()

						await store.dispatch('CurrentDatabase/appendIndexes', {
							queriesDone: [c],
							collectionName,
						})

						console.timeEnd(t1)
					})
			).then(async () => {
				console.timeEnd(t0)
			})
		}
	}

	async onSyncCompleted() {
		const isReplicationCompleted =
				store.getters['CurrentDatabase/isReplicationCompleted'], //TODO
			isSilent =
				store.state?.CurrentDatabase?.initialization?.config?.silent
		let skipValidation =
				store.state?.CurrentDatabase?.initialization?.config
					?.skipValidation
					
		if(!VUE_APP_VALIDATION_ENABLED)
			skipValidation = true

		if (isReplicationCompleted) {
			const timings = store.getters['CurrentDatabase/getTimings']
			const docs_read = store.getters['CurrentDatabase/getDocsRead']
			
			console.table(timings)
			console.table(docs_read)
			
			if (!isSilent) {
				const payload = {
					timings,
					docs_read,
				}
			
				await createEventFromCode('db-sync-success', {
					payload: payload
				})

				self.replicationStates = []
			}

			const isReplicationInitialized =
				store.getters['CurrentDatabase/isReplicationInitialized']

			const config = store.state?.CurrentDatabase?.initialization?.config

			if (!isReplicationInitialized && !skipValidation) {
				console.debug(
					`[onSyncCompleted] rozpoczynanie walidacji`,
					config
				)
				const { collections } =
					store.state?.CurrentDatabase?.initialization

				await this.validateDatabase({ collections })
			} else if (!isReplicationInitialized && skipValidation) {
				console.debug(`[onSyncCompleted] pomijanie walidacji`, config)
			}
		}
	}
}

export default DatabaseService
